<template xmlns="http://www.w3.org/1999/html">
  <div class="content">

    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(rgba(24,62,49,0.2),black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <div class="col-lg-8 col-sm-12">
        <div
          class="centered text-center"
          style="width: 100%; "
          :style="{
              background: `linear-gradient(${this.secondaryColor}, black)`,
            }"
        >

          <HeadImage :image="mainImage" title="Tulum Nightlife"
                     alt="tulum epic nights"
                     :clamp="true"
                     align="text-center"
                     caption="Smart & Up to Date Guide For Party Goers" color="#73854a"/>

          <br>


          <h4>TULUM MEXICO</h4>
          <p>
            The nightlife scene in Tulum is<strong> booming </strong>and the town has quickly become a
            <strong>must-visit</strong> destination for people who enjoys nature, <strong>electronic music</strong>
            and spiritual awareness.
          </p>



        </div>
        <br/>


        <div class="card centered text-center">
          <img v-lazy="bikeImg+'?format=auto&h=60&w=80'" height="60px" width="80px"
               alt="tulum is a bik town"
               class="centered">

          <small style="margin-top: 10px;">TULUM, MX</small>
          <div class="card" style="padding: 30px">
            <p>
              <b>Sometimes you need a room full of strangers</b> to make you feel less alone. <b>A place dark enough to
              make yourself unknown</b>, unknowable <b>but home</b>.
            </p>
          </div>
          <div style="background:linear-gradient(#73854a, black);max-width: 300px" class="centered">

            <ResponsiveImage :clamp="true" :image="bgPalm"></ResponsiveImage>


          </div>

        </div>

        <p class="text-center">
          Have questions? We have answers. Our guide breaks down the essentials with sections on what, why, where, who, and when.
        </p>
        <section
          :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
          class="card text-left"
          style="
            color: #fff !important;
            margin-bottom: 1px;
            padding: 40px;
            margin-top: 20px;
            min-height: 400px;
          "
        >

          <h3 class="tulum-party-subtitle-bg">🔘 WHY ?</h3>


          <p>
            Tulum is not just known for it's stunning scenery and cultural
            significance, but it's also <strong>a hub for non-stop fun</strong>.
            <br/>
            <br/>

            <strong style="font-weight: bold">
              Tulum is the ultimate destination for those seeking to let loose
              on nature and dance the night away.
            </strong>
          </p>

          <hr>
          <p style="padding: 10px; font-style: italic;" >
            "Tulum's party scene offers a diverse range of electronic music styles, including all variations of house & melodic techno."
          </p>

          <div>
            <small style="font-size:x-small">
              * Not a fan of dance music? Try Salsa Nights, Latin music, or HipHop instead.
            </small>
          </div>
          <br>
          <br>
          <p>
            <strong>
              Make a smart choice —add a weekend stop in Tulum during your Riviera Maya adventure!
            </strong>
          </p>
          <br>
        </section>
        <section
          :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
          class="card text-left"
          style="
            color: #fff !important;
            margin-bottom: 1px;
            padding: 40px;
            margin-top: 20px;
            min-height: 400px;
          ">
          <h3 class="tulum-party-subtitle-bg">🔘 WHERE ?</h3>

          <p>Tulum's nightlife scene is spread across <strong>four distinct areas</strong>. The Beach Zone is the epicenter of the action, while Downtown offers a relaxed vibe with extended hours. La Veleta is Tulum's burgeoning hub for diverse entertainment. And The Jungle Host Festivals & RAVE Parties</p>
          <div class="card centered">
            <ImgCard class="centered"
                     text="<p>Mahmut Orhan firing up Bagatelle's Beach Club</p>"
                     align="center" image="https://imgix.cosmicjs.com/19572490-5382-11ef-b1ea-f56c65dfade9-BAGATELLE_TULUM___mahamut.jpg"></ImgCard>

            <mini-carrousel
              title="Tulum's nightlife is diverse and spread out over four distinct areas"
              folder="tulum-nightlife-media" num-photos="4"></mini-carrousel>
          </div>

          <p>
            <strong style="font-size: 1.2rem"> 🔘 Beach Zone</strong>
            <br>
            <br>
            The Beach Zone is where most of the dance clubs and beach parties
            are located (<a href="/tulum-clubs/papaya-playa">Papaya Playa</a>,
            <a href="/tulum-clubs/mia-beach-club-tulum">Mia Tulum</a>,
            <a href="/tulum-clubs/vagalume-tulum">Vagalume Tulum</a>,
            <a href="/tulum-clubs/bonbonniere-tulum"> Bonbonniere</a>) offering a
            lively and energetic atmosphere for those who love to swim, eat,
            drink & dance. <br/><br/>There are plenty of high end restaurants
            that offer a dinner and party vibe as
            <a href="/tulum-clubs/bagatelle-tulum"> Bagatelle</a>, or
            <a href="/tulum-clubs/gitano-tulum"> Gitano Jungle</a>
            <br>
            <br>
          </p>
          <div style="background-color: #4A4A4A; border-radius: 15px;">
            <p style="padding: 7px;">
              The prices in the Tulum Beach area tend to be <strong>higher than in the
              rest of Tulum</strong> (and Mexico!) so be prepared.
            </p>
          </div>
          <p>
            <br>
            It's <strong>important </strong>to be aware that there may be <strong>limitations</strong> to
            operating hours, so it's recommended to <strong>verify the latest guidelines</strong> before making
            any plans.

            <br>
            <br>
            <strong>PRO TIP: </strong><strong style="color: silver;">"the early bird catches the worm" </strong>
            <br>
            <br>
          </p>
          <p>
            <strong style="font-size: 1.2rem"> 🔘 Downtown</strong>
            <br><br>
            Downtown and its surroundings offer a relaxed vibe with bars and restaurants
            catering to a laid-back crowd and typically more relaxed closing hours.
            <br>
            <br>
            The local vibe is mostly around downtown, with diverse places to eat, drink, and dance.
            <strong> Walk around downtown Tulum and follow the music.</strong>
            <br>
            <br>
            <a href="https://www.instagram.com/bestiario.tulum/"><strong
              style="color: aquamarine; text-decoration: underline;"><router-link :to="{path: '/tulum-clubs/bestiario-tulum'}">Bestiario Tulum</router-link></strong></a>
            at downtown hold events from
            monday & wed to sun. <br><br>Check our <a href="/calendar"> <strong>calendar</strong></a> for their event
            list.
            <br>

            <ImgCard
              title="Batey TULUM"
              :image="this.batey"
              align="center"
              sub-title="The Mojito Bar"
              width="250"
              height="250"
              text="<a href='https://www.instagram.com/bateytulum'> Batey Tulum</a> (the mojitos/vw) is a classic. Situated on the
              hearth of Tulum Downtown<br>Batey is a safe bet for every taste.">
            </ImgCard>

            <a href="http://www.radiotulum.com/"><strong style="color: aquamarine; text-decoration: underline;">Radio
              Tulum</strong></a> is
            a community hub and radio station hosting shows and gatherings. Solo
            travelers and hippie-minded individuals will enjoy it. Check their Instagram
            for new events.
            <a href="https://www.instagram.com/radiotulum/"><strong
            >(Radio Tulum)</strong></a>
            <br>
            <br>
            <a href="https://www.instagram.com/santinobartulum">Santino</a> &
            <a href="https://www.instagram.com/strawhat_hostel/">Straw Hat</a>
            are great places to meet locals, featuring reggaeton, rap, trip-hop,
            and Latin music. They host a weekly bar crawl; check their site for more info.
            <br>
            <br>
            <strong>PRO TIP:</strong>There are plenty of small parties going on
            inside
            hostels. (E.g
            <a href="https://www.instagram.com/mayanmonkey_/">Mayan Monkey</a
            >, <a href="https://www.instagram.com/hostelchetulum/">El Che</a>,
            Emotion)
            <br>
            <br>
          </p>
          <p>
            <strong style="font-size: 1.2rem"> 🔘 La Veleta</strong>
            <br>
            La Veleta, Tulum, is the city's emerging hub offering a diverse range of dining and entertainment options.


            El Mercadito is a dynamic space that combines gastronomy, workshops, live music, and fashion.

          </p>
          <p>
            <strong style="font-size: 1.2rem"> 🔘 The Jungle</strong>
            <br><br>
            The Jungle <strong>(parties)</strong> offer a unique experience, with <strong>DJ's spinning music</strong>
            in the midst of the lush jungle setting, creating a <strong>magical</strong> and <strong>unforgettable
            atmosphere.</strong>
            <br>
            <br>
          </p>

          <ImgCard
            height="200"
            width="300"
            align="center"
            :image="zamnaImg"
            text="<p><br><a href='/brands/zamna-tulum'><strong style='color: aquamarine; text-decoration: underline;'>Zamna Tulum</strong></a> is by far the largest representative of the jungle party modality.
            <a href='https://maps.app.goo.gl/iV4xbXggCJ8GdPz99'><strong style='color: aquamarine; text-decoration: underline;'>Zamna's jungle location</strong></a> is about 15 minutes south from downtown.
            <br>  There is parking and taxis (though expensive). It's recommended to <strong>stay until the end</strong> for a cool experience returning with the sunrise.">
          </ImgCard>

          <div style="background-color: #4A4A4A; border-radius: 15px;">
            <p style="padding: 7px;">
              Most jungle parties are 15-25 minutes away.
              Some organizers offer <strong>round-trip shuttles</strong>,
              ensuring easy transportation. <br>We recommend taking
              the shuttle for the best experience.<br>
            </p>
          </div>
          <br>
          <p>
            <strong>PRO TIP:</strong>For the full Tulum after-party experience,
            enjoy morning tacos at
            <a
              href="https://www.tripadvisor.com.mx/Restaurant_Review-g150813-d8125485-Reviews-Taqueria_Honorio-Tulum_Yucatan_Peninsula.html">
              Taqueria Onorio</a> the best
            taqueria in town!.
          </p>

        </section>

        <section
          :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
          class="card text-left"
          style="
            color: #fff !important;
            margin-bottom: 1px;
            padding: 40px;
            margin-top: 20px;
            min-height: 400px;
          ">
          <h3 class="tulum-party-subtitle-bg">🔘 WHAT ?</h3>

          <p>
            Tulum has a <strong>variety of options</strong> to choose from, take the opportunity
            to visit different clubs, combining beach, downtown, and jungle
            parties.
          </p>




          <div>
            <ul style="padding: 7px; margin-top: 10px; ">
              <router-link :to="{ name: 'BeachClubs' }"
              >Beach Clubs
              </router-link>
              <br><br>
              <router-link :to="{ name: 'Clubs' }">Nightclubs
              </router-link>
              <br><br>
              <router-link :to="{ name: 'Home' }">Parties</router-link>
              <br><br>
              <router-link :to="{ name: 'TulumNye2024' }"
              >Festivals
              </router-link>
            </ul>
          </div>

        </section>

        <section
          :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
          class="card text-left"
          style="
            color: #fff !important;
            margin-bottom: 1px;
            padding: 40px;
            margin-top: 20px;
            min-height: 400px;
          ">
          <h3 class="tulum-party-subtitle-bg">🔘 WHEN ?</h3>


          <div class="card text-center">
            Tulum's party scene is lively year-round, with more big festivals
            and parties during <strong>December, January & February</strong>.

            <MiniCarrousel folder="festival-nye" num-photos="4"
                           title="The largest musical celebration for New Year happens at Tulum Mexico"></MiniCarrousel>          </div>

          <p>
            Tulum is the home base for those who come looking for an experience, synchronizing man-made wonders and natural dreams come true.

            <strong>Thrill-seekers and partiers come to Tulum mainly in the winter months</strong> looking for a warm place to have fun and see the beginning of the year.
            <br>This is <strong>the time when the big festivals and immense parties go down </strong>, so it's no surprise that every year this season becomes the <strong>party season</strong>.

          </p>
          <div class="well">
            If you've never come to Tulum, you should try it on <router-link :to="{path: '/nye'}">New Year's Eve</router-link>, and if you've finally pulled the trigger, we want to make sure you know what to do here once you get here

          </div>
          <br>
          <p>
            During a typical week, activity tends to start low on Monday but
            gradually picks up pace as the week progresses,
            <strong>reaching its peak on Friday and Saturday. </strong>
            <br>
          </p>
          <div class="row"  >
            <div style="background-color: #4A4A4A; border-radius: 15px;margin-top: 20px">
              <p style="padding: 15px;">
                There is a distinct <strong>"weekly rhythm"</strong> in Tulum's party
                scene,
                where activity follows a set pattern during a normal week.
                <br>
              </p>

            </div>
            <br>
            <br>
            <div class="col-sm-12 card" style="margin-top: 20px">
              <br>
              <h4 class="section-header"
              >
                Monday
              </h4>
              <br>
              <p >
                On <strong>Monday</strong>, Tulum is relatively quiet. For a lively night, visit
                <router-link :to="{path: '/tulum-clubs/rosa-negra-tulum'}">Rosa Negra</router-link>
                ,                 <router-link :to="{path: '/tulum-clubs/ilios-restaurant-tulum'}">Ilios</router-link>
                , or
                <router-link :to="{path: '/tulum-clubs/taboo-tulum'}">Taboo Tulum</router-link>
                &nbsp; &
                <a href="https://www.instagram.com/la_pizzinetulum/">La Pizzine</a>
                is a <strong>new spot</strong> in La Veleta, has small shows, and you can also explore bars and clubs
                in
                downtown Tulum like Batey Tulum or Santino.
              </p>
            </div>
            <div class="col-sm-12 card">
              <br>
              <h4 class="section-header" >
                Tuesday
              </h4>
              <br>
              <p >
                On <strong>Tuesday</strong>, the party scene heats up with various events and entertainment options.
                Beach clubs and bars host Resident DJ's or seasonal events (e.g.,
                <router-link :to="{path: '/brands/sinner-tulum'}">Sinner </router-link> @ Vagalume
                <br>
                In downtown, just follow the music to find your perfect spot for the night.
              </p>
              <br>
            </div>
            <div class="col-sm-12 card">
              <br>
              <h4 class="section-header" >
                Wednesday
              </h4>
              <br>
              <p >

                <strong>Wednesday</strong> is known for the incendiary
                <router-link :to="{path: '/brands/mystical-sunset-tulum'}">Mystical Sunset </router-link>
               that occur every 15 days, for extended party hours at the beach zone,
                you must include
                <router-link :to="{path: '/tulum-clubs/bonbonniere-tulum'}">Bonbonniere</router-link>
                in your agenda, which lights up the beach area with red lights until
                late from Wednesday to Sunday.
              </p>
            </div>
            <div class="col-sm-12 card">
              <h4 class="section-header" >
                Thursday
              </h4>

              <p >
                <br>
                On<strong> Thursday </strong>
                <router-link :to="{path: '/tulum-clubs/bagatelle-tulum'}">Bagatelle</router-link>
                is becoming the hot spot for its enjoyable
                <router-link :to="{path: '/article/bagatelle-tulum-dinner-party'}">dinner + party sessions</router-link>
                ,it's your best bet if you want to start the party early.
                <br>
                <router-link :to="{path: '/tulum-clubs/vagalume-tulum'}">Vagalume Tulum</router-link>
                is a top pick for dancing and enjoying the night at the beach with their
                Vagalume Sessions nights featuring top international & local DJ's.
                <br/>
                <br/>
                <strong style="color: white;">PRO TIP:</strong> Start at Bagatelle end on
                  <a href="/tulum-clubs/vagalume-tulum"
                  ><strong style="color: aquamarine; text-decoration: underline;">Vagalume Tulum</strong></a
                  >, and experience the full spectrum of Tulum's nightlife.
                <br/>
                <br/>
              </p>
            </div>
            <div class="col-sm-12 card">
              <h4 class="section-header" >
                Friday
              </h4>
              <p>
                <br>
                <strong>Friday</strong> offer a variety of options, and they all start the
                party early with Buuts Has´s Cenote party every Friday starting
                at 2pm.
                <br/>
                In the evening you can head to
                <a href="/tulum-clubs/gitano-tulum">Gitano Jungle</a> for their
                signature Gypsy Disco night, where you can dine and dance under
                the giant disco ball. <br/>For those who are fans of house and
                techno, <a href="/tulum-clubs/bonbonniere-tulum">Bonbonniere</a> & <a href="/tulum-clubs/vagalume-tulum">Vagalume</a> hosts occasional top notch parties on Fridays
                <br/>

                <br/>
              </p>
            </div>
            <div class="col-sm-12 card">
              <h4 class="section-header" >
                Saturday
              </h4>

              <p>
                <strong>Saturday:</strong> is packed with excitement from
                morning till night.
                <a href="/tulum-clubs/taboo-tulum">Taboo</a> is a showstopper
                during the daytime with its iconic sax and happenings, while
                <a href="/tulum-clubs/papaya-playa-tulum">Papaya Playa's</a> party is the
                most popular at night with its well-known Full moon and Moongaze
                parties.
                <br/>
                Just recently Vagalume started a new show series named "Disorder" guaranteed to ignite your senses.
                <br/>
              </p>
            </div>
            <div class="col-sm-12 card">
              <h4 class="section-header" >
                Sunday
              </h4>
              <p>
                <strong>Sunday:</strong> offer unexpected surprises, with
                <a href="/tulum-clubs/bagatelle-tulum">Bagatelle</a> Jungle
                themed brunch during the day followed by
                <a href="/tulum-clubs/vagalume-tulum">Vagalume Tulum</a> in the
                evening, featuring renowned international DJs to cap off the
                week with a bang. Keep an eye on
                <a href="/tulum-clubs/bonbonniere-tulum"
                >Bonbonniere's Sunday late parties</a
                >, an energetic way to end the party week !
              </p>
            </div>
          </div>
        </section>


        <section
          :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
          class="card text-left"
          style="
            color: #fff !important;
            margin-bottom: 1px;
            padding: 40px;
            margin-top: 20px;
            min-height: 400px;
          ">
          <h3>General Recommendations</h3>

          <h4 class="section-header" style="background: #88cd10 !important">
            DO's
          </h4>
          <p>
            <b
            >Varying your experiences is key to having a great time in
              Tulum.</b
            >
            Take the opportunity to visit different clubs, combining beach,
            downtown, and jungle parties for a truly diverse and memorable
            experience.
          </p>

          <p>
            If you have enough time, take the time to travel around the Riviera
            Maya. We highly recommend taking a trip to Bacalar, Valladolid or
            Holbox to relax and enjoy nature.
          </p>

          <br/>
          <h4 class="section-header" style="background: #cd2610 !important">
            DONT'S
          </h4>

          <p>
            <strong>Do not "Drink" & Drive;</strong> You probably will end up in
            jail, crashing or in the best case a policeman will make his day.
            Despite that Quintana Roo's highways are "straight" they are very
            dangerous (as many people fall asleep), so do not drive if you are
            tired or "aftereado". The best bet is to wait until sunrise and
            drive along the sun. Plan how & when you will get back from the
            party ahead of time.
          </p>

          <p>
            The best way to stay away from police bribes is to behave properly.
            Do not drink-drive, use your helmet and don't carry drugs.
          </p>
          <div class="row">
            <h4 class="section-header">
              <i class="tim-icons icon-image-02"></i>GALLERY
            </h4>


            <carrousel folder="tulum-nightlife-media" :color="secondaryColor"></carrousel>
            <p class="text-center">
              <b
              >Varying your experiences is key to having a great time in
                Tulum.</b
              >
              Take the opportunity to visit different clubs, combining beach,
              downtown, and jungle parties for a truly diverse and memorable
              experience.
            </p>
          </div>
        </section>
        <section>

          <SmartMenu :links="links" :logo="this.logo">
            <div class="row">
              <div class="col-sm-12 text-center">
                <p><strong>Feeling Lost?</strong></p>
                <hr>
                <p>
                  We know the scene inside out. Let's curate your perfect night.
                </p>
                <hr>

              </div>
            </div>
          </SmartMenu>
        </section>
      </div>


      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu :links="links" :logo="this.logo" :primaryColor="primaryColor">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <hr>
              <p>
                We know the scene inside out. Let's curate your perfect night.
              </p>
              <hr>

            </div>
          </div>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>
import ImgCard from '@/components/Cards/ImgCard';
import Carrousel from '@/components/Carrousel';
import DynamicLinks from "@/components/DynamicLinks";
import MiniCarrousel from "@/components/MiniCarrousel";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import HeadImage from "@/components/HeadImage/HeadImage";
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";

export default {
  name: 'TulumNightLife',
  created: function () {
    this.$store.dispatch('getLinks', {slug: 'tulum-nightlife'});
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
  },
  props: ['slug'],
  metaInfo() {
    return {
      title: `Tulum NightLife Guide - 2024`,
      meta: [
        {
          name: 'description',
          content: `Tulum NightLife. How to spend your nights in Tulum Mexico.`,
        },
        {
          name: 'keywords',
          content: `Tulum Nightlife, Nightclubs, Bars, Downtown, Party Zone,`,
        },
        {
          property: 'og:title',
          content: `Tulum NightLife. How to spend your nights in Tulum Mexico`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-nightlife`,
        },
        {
          property: 'og:description',
          content: `Tulum NightLife. How to spend your nights in Tulum Mexico`,
        },
        {property: 'og:type', content: 'article'},
        {property: 'og:locality', content: 'Tulum'},
        {property: 'og:country-name', content: 'Mexico'},
        {
          property: 'og:image',
          content:
            'https://imgix.cosmicjs.com/df7ff070-76a4-11eb-96b6-e94a2ed95964-zamna.png',
        },
      ],
    };
  },
  data: () => {
    return {
      showPalms: false,
      logo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      secondaryColor: '#73854a',
      primaryColor: '#5a7898',
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      bikeImg: 'https://imgix.cosmicjs.com/9562a300-47ef-11ef-a504-63e081e4680f-animated-bike.webp',
      batey:
        'https://imgix.cosmicjs.com/d6016a50-bd25-11ed-b7c8-73c00a6d38c7-image.png',
      zamnaImg:
        'https://imgix.cosmicjs.com/8ab84f40-5c05-11ed-a620-259cb7f1b280-telegram-cloud-photo-size-1-5125400631887112900-y.jpg',
      followThatDreamImg:
        'https://imgix.cosmicjs.com/89de8e60-9a96-11ee-b34c-99d1d89e42eb-IMG5765.jpg',
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      mainImage:
        'https://imgix.cosmicjs.com/e1e6a770-dc9d-11ee-b074-b5c8fe3ef189-nightlife-tulum.png',
    };
  },
  components: {
    ResponsiveImage,
    SmartMenu,
    HeadImage,
    MiniCarrousel,
    Carrousel,
    DynamicLinks,
    ImgCard
  },
  methods: {},
};
</script>
